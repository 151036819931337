import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueGtag from "vue-gtag";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import Dayjs from "vue-dayjs";
import VueMeta from "vue-meta";
import VueProgressiveImage from "vue-progressive-image";

// add metadata headers
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

// configure Google Analytics
Vue.use(
    VueGtag,
    {
        config: { id: "G-6E5G4L36EW" },
        appName: "fjwillemsen.com",
    },
    router
);

// configure inline date displays
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(Dayjs, {
    lang: "en",
    filters: {
        ago: "ago",
    },
});

// add progressive images
Vue.use(VueProgressiveImage);

// add mixins
Vue.mixin({
    methods: {
        copyToClipboard(string) {
            navigator.clipboard.writeText(string);
            this.$root.$emit("notify_snackbar", "Copied to cliboard!");
        },
        extendCurrentURLwithString(string) {
            var page = location.href;
            if (page.slice(-1) != "/") {
                page = page + "/";
            }
            return new URL(page + string).toString();
        },
    },
});

Vue.config.productionTip = true;
new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app");

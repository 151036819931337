<template>
    <v-app>

        <v-app-bar app>
            <v-app-bar-nav-icon title="Open / close the menu" @click="drawer = !drawer" style="color: #E6B96F">
                <v-icon>{{ icon_menu }}</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer/>
            <router-link title="Go to Home" to="/" style="text-decoration: none; color: inherit;">
                <v-toolbar-title class="subtitle" style="font-size:2em">fjwillemsen</v-toolbar-title>
            </router-link>
            <v-spacer/>
        </v-app-bar>

        <v-navigation-drawer 
          v-model="drawer"
          app
          temporary
          id="nav"
        >

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    :to="item.link"
                    :title="'Go to ' + item.title"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- If using vue-router -->
            <router-view class="ma-0 pa-0"></router-view>

            <!-- Allow for global notifications using the snackbar tied to emit events -->
            <v-snackbar v-model="snackbar" v-on:notify_snackbar="showSnackbar($event)">
                {{ snackbar_text }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="pink"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>

        </v-main>

        <div id="footer" style="padding-top: 30px; padding-bottom: 10vw">
            © 2021 - {{currentYear}} <br/>
            Floris-Jan Willemsen
            <br/><br/>
            Questions or remarks?<br/>
            <router-link to='/about'>Contact me!</router-link>
        </div>
    </v-app>
</template>

<script>
  import { mdiMenu, mdiHome, mdiPost, mdiFileDocumentMultiple, mdiPresentation, mdiAccount } from "@mdi/js";
  export default {
    name: 'App',
    data: () => (
        { 
            drawer: false,
            snackbar: false,
            snackbar_text: "",
            icon_menu: mdiMenu,
            currentYear: new Date().getFullYear(),
            items: [
                { title: 'Home', icon: mdiHome, link: '/' },
                { title: 'Blog', icon: mdiPost, link: '/blog' },
                { title: 'Publications', icon: mdiFileDocumentMultiple, link: '/publications' },
                { title: 'Posters', icon: mdiPresentation, link: '/posters' },
                { title: 'About', icon: mdiAccount, link: '/about' },
            ], 
        }
    ),
    methods: {
        showSnackbar: function(message) {
            this.snackbar = true;
            this.snackbar_text = message;
        }
    },
    mounted() {
        // set the watcher for snackbar notifications
        this.$root.$on('notify_snackbar', this.showSnackbar);
    },
    metaInfo: {
        title: 'My research, publications, talks, blog posts',
        titleTemplate: '%s | Floris-Jan Willemsen',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [ 
            { name: 'description', content: 'The personal website of Floris-Jan Willemsen, PhD student at the Netherlands eScience Center, Leiden University and University of Amsterdam', },
            { name: 'author', content: 'Floris-Jan Willemsen' },
            { name: 'keywords', content: 'fjwillemsen, Floris-Jan, Willemsen, PhD, Amsterdam, Leiden, university, research, GPU, computer science, HPC' },
            { charset: 'utf-8' },
        ],
    }
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@800&family=Montserrat&family=Raleway&display=swap');
// fonts: Copperplate / Baskerville for titles, Montserrat for body, Raleway for special / subtitles

// hide the scrollbar from view
::-webkit-scrollbar {
    width: 0px;
}

html {
    background-color: #232323;
}

html, body {
    overflow-x: hidden !important;
}

a, .a {
    color: #C86835 !important;
}

#app {
    font-family: 'Montserrat', sans-serif;
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
}

.bigtitle {
    color: #E6B96F;
    font-family: 'Libre Baskerville', serif;
    font-variant: small-caps;
}

.subtitle {
    color: #E6B96F;
    font-family: "Raleway", 'Montserrat', sans-serif;
}

#nav {
    padding: 0px;
    padding-top: 63px;

    a, .a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }

    i.v-icon.v-icon, .v-icon {
        color: #E6B96F;
    }
}

#footer {
    background-color: #232323;
}

</style>

// background color: #000D1A
// accent background color: #272727
// font color: #E6B96F
// accent color: #942B47 or #D0434A
// link color: #C86835
// navbar / footer color: #232323

import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueScrollReveal from "vue-scroll-reveal";
import vBlur from "v-blur";

Vue.use(Vuetify);

// for affixing any element while scrolling.
Vue.use(vBlur);

// for revealing elements when scrolling past them.
Vue.use(VueScrollReveal, {
    class: "v-scroll-reveal", // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
    duration: 800,
    scale: 1,
    distance: "10px",
    mobile: false,
    viewOffset: {
        top: 100,
        right: 0,
        bottom: 0,
        left: 0,
    },
});

// export this setup
export default new Vuetify({
    theme: {
        dark: true,
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
    },
});

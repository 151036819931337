const pathlib = require("path");

function lazyload(view) {
    // Applies route level code-splitting
    //   Generates a separate chunk file for the view, which is only loaded on visit.
    //   If you want to group imports, use the 'webpackChunkName' comment syntax.
    return () => import(`@/views/${view}.vue`);
}

async function getKeysOfJSON(filepath) {
    // Function to get the top-level keys of a JSON file, relative to the 'content' directory
    const url = pathlib.resolve(__dirname, `./../content/${filepath}`);
    const data = require(url);
    return Object.keys(data);
}

const routes = [
    {
        path: "/",
        alias: "/index.html",
        name: "HomePage",
        component: lazyload("HomePage"),
        meta: {
            sitemap: {
                changefreq: "monthly",
                priority: 0.9,
            },
        },
    },
    {
        path: "/blog/:name",
        name: "BlogPost",
        component: lazyload("blog/BlogPost"),
        meta: {
            sitemap: {
                slugs: async () => await getKeysOfJSON("blogs/index.json"),
                changefreq: "monthly",
                priority: 0.9,
            },
        },
    },
    {
        path: "/blog",
        name: "BlogOverview",
        component: lazyload("blog/BlogOverview"),
        meta: {
            sitemap: {
                changefreq: "monthly",
                priority: 0.9,
            },
        },
    },
    {
        path: "/publications/:id",
        name: "PublicationForward",
        component: lazyload("publications/PublicationForward"),
        meta: {
            sitemap: {
                slugs: async () => await getKeysOfJSON("publications/index.json"),
            },
        },
    },
    {
        path: "/publications",
        name: "PublicationOverview",
        component: lazyload("publications/PublicationOverview"),
    },
    {
        path: "/posters/:id",
        name: "PosterForward",
        component: lazyload("posters/PosterForward"),
        meta: {
            sitemap: {
                slugs: async () => await getKeysOfJSON("posters/index.json"),
            },
        },
    },
    {
        path: "/posters",
        name: "PosterOverview",
        component: lazyload("posters/PosterOverview"),
    },
    {
        path: "/about",
        name: "AboutMe",
        component: lazyload("AboutMe"),
    },
    {
        path: "/*",
        name: "404",
        component: lazyload("status/status_404"),
    },
];

module.exports = {
    routes,
};
